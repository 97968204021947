<template>
  <div v-if="show" class="orientations-modal" ref="orientations-modal">
    <div class="modal-content" ref="modal-content">
      <div class="modal-content__header" ref="orientations-modal__header">
        <span>Orientações</span>
        <button @click="closeModal"><Close style="fill: gray; width: 20px;"/></button>
      </div>
      <div class="modal-content__tags-container">
        <div class="modal-content__tags">
          <button v-if="permittedTags.includes('professional')" :class="{'selected-tag': selectedTag === 'professional'}" @click="selectedTag = 'professional'">Profissional</button>
          <button v-if="permittedTags.includes('clinic')" :class="{'selected-tag': selectedTag === 'clinic'}" @click="selectedTag = 'clinic'">Clínica</button>
          <button v-if="permittedTags.includes('health_plan')" :class="{'selected-tag': selectedTag === 'health_plan'}" @click="selectedTag = 'health_plan'">Convênio</button>
        </div>
        <span v-if="!reloading" class="reload-label" @click="reloadData">
          <Loading 
           :class="{ icon: true, reload: true, loading }" 
           v-b-tooltip.hover.left="'Recarregar'"
           @click="() => !loading && reloadData()"
          />
        </span>
      </div>
      <div class="modal-content__body">
        <div v-show="selectedTag === 'professional'" class="modal-content__body__professional">
          <div class="professional-search">
            <span class="input-label">Profissional</span>
            <multiselect
                v-model="selectedProfessionals"
                multiple
                :options="professionalsList"
                :track-by="'id'"
                :label="'name'"
                :show-labels="false"
                :allow-empty="true"
                placeholder=""
                :loading="loading"
                :close-on-select="false"
                :searchable="true"
                class="multiple"
                @select="getDoctorData"
            >
              <template slot="caret">
                <div v-if="selectedProfessionals.length" class="close-icon">
                  <Close v-if="!loading" class="icon" @click="selectedProfessionals = []" />
                </div>
                <div v-else class="close-icon">
                  <ChevronDown  />
                </div>
              </template>
              <template slot="selection" slot-scope="{ values }">
                <span class="selectedOptions">
                  <p>{{ generateProfessionalsLabels(values) }}</p>
                </span>
              </template>
              <template slot="noResult">
                Nenhum resultado encontrado.
              </template>
            </multiselect>
          </div>
          <div class="professionals-list">
            <div v-for="p in selectedProfessionals" :key="p.id" class="professional-card">
              <div class="professional-card__header" @click="expandOrContract(p.id)">
                <div>
                  <img v-if="p.picture" :src="p.picture" alt="Foto do profissional" class="professional-picture"/>
                  <Avatar v-else class="professional-picture"/>
                  <span :title="p.name">{{ parseName(p.name) }}</span>
                </div>
                <ChevronDown class="chevron" :class="{'rotate-chevron': isExpanded.includes(p.id)}" />
              </div>
              <div class="professional-card__body" v-show="isExpanded.includes(p.id)">
                <div v-if="p.constraints?.length" class="d-flex flex-column">
                  <span class="label">Restrições de idade</span>
                  <div v-for="c in p.constraints" :key="c.max">
                    <span>De {{ c.min }} a {{ c.max }} anos</span>
                  </div>
                </div>
                <div>
                  <div v-if="p.crms?.length" >
                    <div v-for="crm in p.crms" :key="crm.id">
                      <span class="label">CRM: </span>
                      <span>{{ crm.number }}/{{crm.state}} {{crm.main ? '(principal)' : ''}}</span>
                    </div>
                  </div>
                  <div v-if="p.rqes?.length" >
                    <div v-for="rqe in p.rqes" :key="rqe.id">
                      <span class="label">RQE: </span>
                      <span>{{ rqe.state }} {{rqe.number}} / {{ rqe.specialty}} {{rqe.main ? '(principal)' : ''}}</span>
                    </div>
                  </div>
                </div>
                <div v-if="p.healthPlans?.length">
                  <div v-for="plan in p.healthPlans" :key="plan.id">
                    <span class="health-plan-name"><HealthPlanLight /> {{ plan.name }} </span>
                  </div>
                </div>
                <hr>
                <div class="orientations-section">
                  <span class="label"><Description /> Orientações</span>
                  <div v-if="p.orientations" v-html="p.orientations"></div>
                  <div v-else>Não há orientações configuradas.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="selectedTag === 'clinic'" class="modal-content__body__clinic">
          <div class="clinic-list">
            <div class="clinic-card">
              <div class="clinic-card__header">
                <Clinic />
                <span>Orientações gerais da clínica</span>
              </div>
              <div class="clinic-card__body">
                <div v-if="clinicOrientations" v-html="clinicOrientations"></div>
                <div v-else>Não há orientações disponíveis.</div>
                <hr>
                <span class="file-title"><File /> Documentos</span>
                <FileUploader :type="'clinic'" :userCanUpload="userCanUpload('clinic')" :userCanDelete="userCanDelete('clinic')" :listOnly="true" />
              </div>
            </div>
          </div>
        </div>
        <div v-show="selectedTag === 'health_plan'" class="modal-content__body__health-plan">
          <div class="health-plan-search">
            <span class="input-label">Convênio</span>
            <multiselect
                v-model="selectedHealthPlans"
                multiple
                :options="healthPlansList"
                :track-by="'id'"
                :label="'name'"
                :show-labels="false"
                :allow-empty="true"
                placeholder=""
                :loading="loading"
                :close-on-select="false"
                :searchable="true"
                class="multiple"
                @select="getHealthPlanData"
                :noOptions="'Nenhum resultado encontrado.'"
            >
              <template slot="caret">
                <div v-if="selectedHealthPlans.length" class="close-icon">
                  <Close v-if="!loading" class="icon" @click="selectedHealthPlans = []" />
                </div>
                <div v-else class="close-icon">
                  <ChevronDown  />
                </div>
              </template>
              <template slot="selection" slot-scope="{ values }">
                <span class="selectedOptions">
                  <p>{{ generateHealthPlansLabels(values) }}</p>
                </span>
              </template>
              <template slot="noResult">
                Nenhum resultado encontrado.
              </template>
            </multiselect>
          </div>
          <div class="health-plan-list">
            <div v-for="hp in selectedHealthPlans" :key="hp.id" class="health-plan-card">
              <div class="health-plan-card__header" @click="expandOrContract(hp.id)">
                <div class="health-plan-name-container">
                  <HealthPlanLight />
                  <span :title="hp.name">{{ parseName(hp.name) }}</span>
                </div>
                <ChevronDown class="chevron" :class="{'rotate-chevron': isExpanded.includes(hp.id)}" style="width: 24px" />
              </div>
              <div class="health-plan-card__body" v-show="isExpanded.includes(hp.id)">
                <div v-if="hp.orientations" v-html="hp.orientations"></div>
                <div v-else>Não há orientações disponíveis.</div>
                <hr>
                <span class="file-title"><File /> Documentos</span>
                <FileUploader :type="'health_plan'" :healthPlanId="hp.id" :userCanUpload="userCanUpload('health_plan')" :userCanDelete="userCanDelete('health_plan')" :listOnly="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import interact from 'interactjs'
import FileUploader from "@/views/Configs/Components/FileUploader.vue";
import { userHasPermission } from '@/utils/localStorageManager'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FileUploader,
    Avatar: () => import('@/assets/icons/avatar.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    HealthPlanLight: () => import('@/assets/icons/health-plan-light.svg'),
    Description: () => import('@/assets/icons/new-description.svg'),
    Clinic: () => import('@/assets/icons/new-clinic.svg'),
    File: () => import('@/assets/icons/file.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Loading: () => import('@/assets/icons/loading.svg')
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      initialPosition: { x: 0, y: 0 },
      selectedTag: null,
      professionalsList: [],
      healthPlansList: [],
      selectedProfessionals: [],
      selectedHealthPlans: [],
      loading: false,
      isExpanded: [],
      clinicOrientations: null,
      reloading: false,
      permittedTags: []
    }
  },
  mounted(){
    this.getProfessionals()
    this.getHealthPlans()
    this.getClinicOrientations()
    this.userHasPerm()
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          const rect = this.$refs['orientations-modal'].getBoundingClientRect()
          this.initialPosition = { x: rect.left, y: rect.top }
          interact(this.$refs['orientations-modal__header'])
              .draggable({
                inertia: true,
                modifiers: [
                  interact.modifiers.restrictRect({
                    restriction: {
                      top: window.innerHeight,
                      left: 0,
                      right: window.innerWidth,
                      bottom: 0
                    },
                    endOnly: true
                  })
                ],
                autoScroll: true,
                onmove: this.dragMoveListener,
                onend: this.dragEndListener
              })
        })
      } else {
        localStorage.setItem('show_orientations_button', true);
      }
    },
    permittedTags(newVal){
      if(newVal.length){
        this.selectedTag = newVal[0];
      }
    }
  },
  methods: {
    userCanUpload(type){
      if (type === 'health_plan') {
        return userHasPermission('OrConv3')
      } else {
        return userHasPermission('OrCli3')
      }
    },
    userCanDelete(type){
      if (type === 'health_plan') {
        return userHasPermission('OrConv4')
      } else {
        return userHasPermission('OrCli4')
      }
    },
    userHasPerm(){
      this.permittedTags = [];
      const tempArray = [];

      if(userHasPermission('OrPro1') || userHasPermission('OrPro2')){
        tempArray.push('professional')
      }
      if(userHasPermission('OrCli1') || userHasPermission('OrCli2')){
        tempArray.push('clinic')
      }
      if(userHasPermission('OrConv1') || userHasPermission('OrConv2')){
        tempArray.push('health_plan')
      }

      this.permittedTags = tempArray;
    },
    closeModal() {
      this.$emit('update:show', false)
    },
    dragMoveListener (event) {
      const target = this.$refs['orientations-modal']
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

      target.style.webkitTransform =
          target.style.transform =
              'translate(' + x + 'px, ' + y + 'px)'

      target.setAttribute('data-x', x)
      target.setAttribute('data-y', y)
    },
    dragEndListener (event) {
      const target = this.$refs['orientations-modal']
      const x = this.initialPosition.x + (parseFloat(target.getAttribute('data-x')) || 0)
      let y = this.initialPosition.y + (parseFloat(target.getAttribute('data-y')) || 0)

      if (y < 0) {
        y = 0
      }

      if (y + target.offsetHeight > window.innerHeight) {
        y = window.innerHeight - target.offsetHeight
      }


      target.style.top = y + 'px'
      target.style.left = x + 'px'
      target.style.transform = 'none'

      target.setAttribute('data-x', 0)
      target.setAttribute('data-y', 0)

      this.initialPosition = { x: x, y: y }
    },
    getClinicOrientations(){
      this.clinicOrientations = '<h4><strong style="color: rgb(187, 187, 187);">Carregando...</strong></h4>'
      const payload = {
        clinic_id: this.clinic.id,
        related_id: this.clinic.id,
        type: 'clinic',
      }

      this.api.getOrientations(payload).then(({data}) => {
        if(Object.keys(data).length) {
          this.clinicOrientations = data.description;
        } else {
          this.clinicOrientations = null;
        }
      }).catch(error => {
        console.error('erro ao buscar orientaçoes', error)
        this.$toast.error('Ocorreu um erro ao buscar orientações')
        this.clinicOrientations = null;
      })
    },
    async getHealthPlanData(healthPlan, reloading = false){
      if(reloading){
        this.loading = true;
        const healthPlanIds = this.selectedHealthPlans.map(el => el.id);

        try {
          const payload = {
            clinic_id: this.clinic.id,
            related_ids: healthPlanIds,
            type: 'health_plan',
          }
          const { data } = await this.api.getBatchOrientations(payload);
          if (data.length) {
            data.forEach(hp => {
              const healthPlanInfo = this.selectedHealthPlans.find(el => el.id === hp.id);
              if(healthPlanInfo){
                const index = this.selectedHealthPlans.indexOf(healthPlanInfo);
                this.selectedHealthPlans[index] = {...healthPlanInfo, orientations: hp.description};
              }
            })
          }
        } catch (err) {
          console.error('Err', err)
        } finally {
          this.loading = false;
        }
        return
      }
      const healthPlanId = healthPlan.id;
      this.loading = true;
      try {
          const healthPlanInfo = this.selectedHealthPlans.find(el => el.id === healthPlanId);
          if(healthPlanInfo){
            const payload = {
              clinic_id: this.clinic.id,
              related_id: healthPlanId,
              type: 'health_plan',
            }
            const {data} = await this.api.getOrientations(payload);
            if(Object.keys(data).length) {
              const index = this.selectedHealthPlans.indexOf(healthPlanInfo);
              this.selectedHealthPlans[index] = {...healthPlanInfo, orientations: data.description};
            }
          }
      } catch (err) {
        console.error('Err', err)
      } finally {
        this.loading = false
      }
    },
    getProfessionals(){
      this.loading = true;
      this.api.getAllClinicDoctors(this.clinic.id).then(({data}) => {
        if(data?.doctors){
          this.professionalsList = data.doctors;
        }
      }).catch(err => console.error('Err', err))
      .finally(() => {
        this.professionalsList = this.professionalsList.sort((a, b) => a.name.localeCompare(b.name))
        this.loading = false;
      })
    },
    async getDoctorData(doctor, reloading = false){
      if(reloading){
        this.loading = true;
        const doctorIds = this.selectedProfessionals.map(el => el.id);
        try {
          const { data } = await this.api.getBatchDoctorData(doctorIds, this.clinic.id);
          if (data.length) {
            data.forEach(doc => {
              let enabledHealthPlans = [];
              let additionalHealthPlans = [];
              const doctorInfo = this.selectedProfessionals.find(el => el.id === doc.id);
              if(doctorInfo){
                enabledHealthPlans = doc.healthPlans?.length ? doc.healthPlans : [];
                additionalHealthPlans = doc.additionalHealthPlans?.length ? doc.additionalHealthPlans : [];
                const healthPlans = enabledHealthPlans.concat(additionalHealthPlans);
                const index = this.selectedProfessionals.indexOf(doctorInfo);
                this.selectedProfessionals[index] = {...doctorInfo, ...doc, healthPlans};
                if(doc.constraints){
                  this.selectedProfessionals[index].constraints = JSON.parse(doc.constraints);
                }
              }
            })
          }
        } catch (err) {
          console.error('Err', err)
        } finally {
          this.loading = false;
        }
        return
      }

      const doctorId = doctor.id;
      this.loading = true;
      try {
        const { data } = await this.api.getDoctorData(doctorId, this.clinic.id);
        if (Object.keys(data).length) {
          let enabledHealthPlans = [];
          let additionalHealthPlans = [];
          const doctorInfo = this.selectedProfessionals.find(el => el.id === doctorId);
          if(doctorInfo){
            enabledHealthPlans = data.healthPlans?.length ? data.healthPlans : [];
            additionalHealthPlans = data.additionalHealthPlans?.length ? data.additionalHealthPlans : [];
            const healthPlans = enabledHealthPlans.concat(additionalHealthPlans);
            const index = this.selectedProfessionals.indexOf(doctorInfo);
            this.selectedProfessionals[index] = {...doctorInfo, ...data, healthPlans};
            if(data.constraints){
              this.selectedProfessionals[index].constraints = JSON.parse(data.constraints);
            }
          }
        }
      } catch (err) {
        console.error('Err', err)
      } finally {
        this.loading = false
      }
    },
    getHealthPlans(){
      this.loading = true
      this.healthPlansList = [];
      this.api.getAllClinicHealthPlans(this.clinic.id)
      .then(({data}) => {
        if(data.length){
          data.map(plan => {
            if(plan.health_plan){
              const tempObj = {
                id: plan.id,
                name: plan.health_plan.fantasy_name
              }
              this.healthPlansList.push(tempObj)
            }
          })
        }
      })
      .catch(err => {
        console.error('erro', err)
      }).finally(() => {
        this.healthPlansList = this.healthPlansList.sort((a, b) => a.name.localeCompare(b.name))
        this.loading = false
      })
    },
    generateProfessionalsLabels(values) {
      let labelString = ''
      if (values.length === 0) {
        if (this.isLoading) {
          labelString = 'Carregando...'
        } else {
          labelString = 'Selecione os profissionais...'
        }
      } else if (values.length === this.professionalsList.length) {
        labelString = 'Todos os profissionais'
      } else {
        const limit = 2;
        const excess = values.length - limit;
        values.slice(0, limit).forEach((el, index) => {
          const nameParts = el.name.split(' ');
          const fullName = nameParts.length > 1 ? `${nameParts[0]} ${nameParts[nameParts.length - 1]}` : el.name;
          labelString += fullName;
          if (index !== limit - 1 && index !== values.length - 1) {
            labelString += ' / ';
          }
        })
        if (excess > 0) {
          labelString += ` +${excess}`;
        }
      }
      return labelString
    },
    generateHealthPlansLabels(values){
      let labelString = ''
      if (values.length === 0) {
        if (this.isLoading) {
          labelString = 'Carregando...'
        } else {
          labelString = 'Selecione os convênios...'
        }
      } else if (values.length === this.professionalsList.length) {
        labelString = 'Todos os convênios'
      } else {
        const limit = 2;
        const excess = values.length - limit;
        values.slice(0, limit).forEach((el, index) => {
          const fullName = el.name.length > 30 ? el.name.slice(0, 30) + '...' : el.name;
          labelString += fullName;
          if (index !== limit - 1 && index !== values.length - 1) {
            labelString += ' / ';
          }
        })
        if (excess > 0) {
          labelString += ` +${excess}`;
        }
      }
      return labelString
    },
    expandOrContract(id){
      if(this.isExpanded.includes(id)){
        this.isExpanded = this.isExpanded.filter(el => el !== id);
      } else {
        this.isExpanded.push(id);
      }
    },
    parseName(name){
      if(name.length > 30){
        return name.substring(0, 30) + '...'
      }
      return name
    },
    async reloadData(){
      this.reloading = true;
      if(this.selectedTag === 'professional'){
        await this.getProfessionals()
        await this.getDoctorData(null, true);
      } else if(this.selectedTag === 'health_plan'){
        await this.getHealthPlans()
        await this.getHealthPlanData(null, true);
      } else {
        await this.getClinicOrientations();
      }
      this.reloading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.orientations-modal {
  position: fixed;
  z-index: 9998;
  right: 2%;
  top: 13%;
  width: 450px;
  height: 750px;
  max-height: 750px;
  border-radius: 8px;
  box-shadow: 0 24px 48px -8px #0C1D5933;

}

.modal-content {
  font-family: "Nunito Sans",serif;
  position: absolute;
  background-color: #fefefe;
  border: 1px solid lightgray;
  width: 100%;
  height: 100%;
  gap: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    font-weight: bold;
    border-bottom: 1px solid lightgray;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  &__tags {
    display: flex;
    gap: 8px;

    button {
      background-color: white;
      border: 1px solid lightgray;
      border-radius: 20px;
      padding: 4px 8px;
      transition: background-color 0.3s;
      color: black;
      font-size: 13px;
      display: inline-flex;
      align-items: center;

      &.selected-tag {
        background-color: var(--primary);
        color: white;

        &:hover {
          background-color: var(--primary);
          opacity: 0.9;
        }
      }
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__tags-container {
    display: flex;
    justify-content: space-between;
    padding: 0 24px;

    .reload-label {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      font-weight: bold;
      color: #0C1D59;
      cursor: pointer;
      text-align: center;
    }

    .reload-icon {
      fill: #0B30B2;
    }

  }

  &__body {
    height: 100%;
    overflow-y: auto;
    padding: 0 24px 24px 24px;

    .input-label {
      padding-left: 1px;
      font-size: 16px;
      font-weight: 600;
    }

    &__professional {
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 100%;

      .professional-search {
        display: flex;
        flex-direction: column;
        gap: 8px;

        ::v-deep .multiselect {
          border-radius: 5px;
          border: 1px solid #C6CEEB;
          box-sizing: border-box;
        }
        ::v-deep .multiselect__spinner {
          background: white;
          animation: spin-5845c06c 2s linear infinite;
          border-radius: 60%;
          top: 8px;
          right: 10px;
          z-index: 5;
          height: 20px;
          width: 20px;
        }
        ::v-deep .multiselect__options {
          box-sizing: border-box;
        }

        ::v-deep .multiselect__input {
          font-size: 14px;
        }

        ::v-deep .multiselect__tags {
          max-height: unset !important;
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-evenly;
          align-items: flex-start;
          padding: 4px;

          .selectedOptions {
            margin: 4px;
            white-space: nowrap;
          }
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      }

      .professionals-list::-webkit-scrollbar {
        width: 8px;
      }

      .professionals-list::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      .professionals-list::-webkit-scrollbar-thumb {
        background: #A4B1DF;
        border-radius: 40px;
      }

      .professionals-list::-webkit-scrollbar-thumb:hover {
        background: #7887bd;
      }

      .professionals-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        overflow-y: auto;
        padding: 0 10px;

        .professional-card {
          display: flex;
          flex-direction: column;
          gap: 8px;
          border: 1px solid lightgray;
          border-radius: 8px;
          width: 100%;

          &__header {
            display: flex;
            gap: 8px;
            align-items: center;
            background-color: #F4F5FB;
            padding: 12px 16px;
            border-radius: 8px;
            justify-content: space-between;

            .chevron {
              cursor: pointer;
              transition: 0.5s;
            }

            .rotate-chevron {
              transform: rotate(180deg);
            }

            div {
              display: flex;
              gap: 8px;
            }

            span {
              font-size: 14px;
              font-weight: 700;
              color: #0C1D59;
              align-self: center;
            }

            svg {
              stroke-width: 1;
              width: 24px;
              height: 24px;
            }

            .professional-picture {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }

          &__body {
            display: flex;
            flex-direction: column;
            gap: 16px;
            font-size: 14px;
            padding: 12px 16px;

            hr {
              border: none;
              border-top: 2px solid lightgray;
              margin: 0;
            }

            .label {
              font-weight: 600;
            }

            .orientations-section {
              display: flex;
              flex-direction: column;
              gap: 8px;
              
              .label {
                display: flex;
                gap: 8px;
                align-items: center;
              }
            }
          }
        }
      }
    }

    &__health-plan {
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 100%;

      .health-plan-search {
        display: flex;
        flex-direction: column;
        gap: 8px;

        ::v-deep .multiselect {
          border-radius: 5px;
          border: 1px solid #C6CEEB;
          box-sizing: border-box;
        }
        ::v-deep .multiselect__spinner {
          background: white;
          animation: spin-5845c06c 2s linear infinite;
          border-radius: 60%;
          top: 8px;
          right: 10px;
          z-index: 5;
          height: 20px;
          width: 20px;
        }
        ::v-deep .multiselect__options {
          box-sizing: border-box;
        }

        ::v-deep .multiselect__input {
          font-size: 14px;
        }

        ::v-deep .multiselect__tag {
          border-radius: 20px;
          background: rgba(0, 0, 0, 0.08);
          color: black;
          font-size: 13px;
          margin: 0;

          &-icon{
            background: rgba(0, 0, 0, 0.26);
            color: white;
            margin: 3px;
            width: 15px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;

            &:after {
              color: white;
            }
          }
        }

        ::v-deep .multiselect__tags-wrap {
          width: 100%;
        }

        ::v-deep .multiselect__tags {
          max-height: unset !important;
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-evenly;
          align-items: flex-start;
          padding: 4px;

          .selectedOptions {
            margin: 4px;
          }
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      }

      .health-plan-list::-webkit-scrollbar {
        width: 8px;
      }

      .health-plan-list::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      .health-plan-list::-webkit-scrollbar-thumb {
        background: #A4B1DF;
        border-radius: 40px;
      }

      .health-plan-list::-webkit-scrollbar-thumb:hover {
        background: #7887bd;
      }

      .health-plan-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        overflow-y: auto;
        padding: 0 10px;

        .health-plan-card {
          display: flex;
          flex-direction: column;
          gap: 8px;
          border: 1px solid lightgray;
          border-radius: 8px;
          width: 100%;

          &__header {
            display: flex;
            gap: 8px;
            align-items: center;
            background-color: #EAEFFE;
            padding: 12px 16px;
            border-radius: 8px;
            justify-content: space-between;

            .health-plan-name-container {
              width: 100%;
              overflow-x: hidden;

              span {
                width: 100%;
                overflow-x: hidden;
              }
            }

            .chevron {
              cursor: pointer;
              transition: 0.5s;
            }

            .rotate-chevron {
              transform: rotate(180deg);
            }

            div {
              display: flex;
              gap: 8px;
            }

            span {
              font-size: 14px;
              font-weight: 700;
              color: #0C1D59;
              align-self: center;
            }

            svg {
              stroke-width: 1;
              height: 24px;
            }
          }

          &__body {
            display: flex;
            flex-direction: column;
            gap: 16px;
            font-size: 14px;
            padding: 12px 16px;

            hr {
              border: none;
              border-top: 2px solid lightgray;
              margin: 0;
            }

            .label {
              font-weight: 600;
            }

            .file-title {
              display: flex;
              font-size: 14px;
              font-weight: 600;
              align-items: center;
              gap: 8px;

              svg {
                width: 17px;
                height: 17px;
                stroke: #305BF2;
                stroke-width: 1;
              }
            }

            .orientations-section {
              display: flex;
              flex-direction: column;
              gap: 8px;

              .label {
                display: flex;
                gap: 8px;
                align-items: center;
              }
            }
          }
        }
      }
    }

    &__clinic {
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 100%;

      .clinic-list::-webkit-scrollbar {
        width: 8px;
      }

      .clinic-list::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      .clinic-list::-webkit-scrollbar-thumb {
        background: #A4B1DF;
        border-radius: 40px;
      }

      .clinic-list::-webkit-scrollbar-thumb:hover {
        background: #7887bd;
      }

      .clinic-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        overflow-y: auto;
        padding: 0 10px;

        .clinic-card {
          display: flex;
          flex-direction: column;
          gap: 8px;
          border: 1px solid lightgray;
          border-radius: 8px;
          width: 100%;

          &__header {
            display: flex;
            gap: 8px;
            align-items: center;
            background-color: #EAEFFE;
            padding: 12px 16px;
            border-radius: 8px;

            .chevron {
              cursor: pointer;
              transition: 0.5s;
            }

            .rotate-chevron {
              transform: rotate(180deg);
            }

            div {
              display: flex;
              gap: 8px;
            }

            span {
              font-size: 14px;
              font-weight: 700;
              color: #0C1D59;
              align-self: center;
            }

            svg {
              stroke-width: 1;
              width: 24px;
              height: 24px;
            }
          }

          &__body {
            display: flex;
            flex-direction: column;
            gap: 16px;
            font-size: 14px;
            padding: 12px 16px;

            hr {
              border: none;
              border-top: 2px solid lightgray;
              margin: 0;
            }

            .label {
              font-weight: 600;
            }

            .file-title {
              display: flex;
              font-size: 14px;
              font-weight: 600;
              align-items: center;
              gap: 8px;

              svg {
                width: 17px;
                height: 17px;
                stroke: #305BF2;
                stroke-width: 1;
              }
            }

            .orientations-section {
              display: flex;
              flex-direction: column;
              gap: 8px;

              .label {
                display: flex;
                gap: 8px;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}

.close-icon {
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  position: absolute;
  transition: all 500ms;
  cursor: pointer;
  z-index: 0;

  svg {
    stroke-width: 1;
    width: 24px;
    height: 24px;
    stroke: lightgray;
  }
}

.transition-effect {
  transition: transform 0.5s;
}
</style>